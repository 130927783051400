export const SORT_OPTIONS = [
  {
    value: 'proposals',
    name: 'Proposals',
    count: true,
  },
];

export const EXPLORE_FILTER_OPTIONS = [
  {
    name: 'Mainnet',
    value: 1,
    type: 'network',
    default: true,
  },
];
